import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 4-4-4-4 to a 4RM`}</p>
    <p>{`then,`}</p>
    <p>{`6-9-12-9-6 reps each of:`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`Deadlifts (185/125)`}</p>
    <p>{`T2B`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`6:00 AMRAP of:`}</p>
    <p>{`6-SDHP’s (95/65)`}</p>
    <p>{`6-Jumping Lunges per leg`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The 2018 CrossFit Open is over.  Congrats to all that competed!  It
was motivating to watch everyone push so hard on each workout and see
many of you do things you never thought possible!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      